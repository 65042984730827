import { createContext, useContext } from 'react';

import { BoxProps } from '@sparky/types';

import { ColumnArrangement } from './SplitView';

interface Props {
  columnArrangement?: ColumnArrangement;
  backgroundFirst?: BoxProps['backgroundColor'];
  backgroundSecond?: BoxProps['backgroundColor'];
  hasOverlap?: boolean;
}

export const SplitViewContext = createContext<Props>({
  columnArrangement: undefined,
  backgroundFirst: undefined,
  backgroundSecond: undefined,
  hasOverlap: undefined,
});

export const useSplitView = () => {
  return useContext(SplitViewContext);
};
