import React, { FC } from 'react';

import { SplitView as SplitViewComponent } from '@components/SplitView/SplitView';
import { Placeholder } from '@sitecore/common';
import { SplitViewRendering } from '@sitecore/types/manual/SplitView';
import { Stack } from '@sparky';

const SplitView: FC<SplitViewRendering> = ({ params }) => {
  const { bringToFront, backgroundFirst, backgroundSecond, columnArrangement } = params ?? {};

  return (
    <SplitViewComponent columnArrangement={columnArrangement}>
      <SplitViewComponent.Item backgroundColor={backgroundFirst} bringToFront={bringToFront === 'first'}>
        <Stack gap={6}>
          <Placeholder name="jss-split-view-first" />
        </Stack>
      </SplitViewComponent.Item>
      <SplitViewComponent.Item backgroundColor={backgroundSecond} bringToFront={bringToFront === 'second'}>
        <Stack gap={6}>
          <Placeholder name="jss-split-view-second" />
        </Stack>
      </SplitViewComponent.Item>
    </SplitViewComponent>
  );
};

export default SplitView;
