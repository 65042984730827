import React, { FC } from 'react';

import ReactIs from 'react-is';

import logger from '@common/log';
import { Grid } from '@sparky';

import { SplitViewContext } from './SplitViewContext';
import { SplitViewItem } from './SplitViewItem';
import { getGridProperties, getOverlappingGridProperties } from './SplitViewProperties';

export const SplitView: FC<Props> & SplitViewProps = ({ columnArrangement = '8-4', children: childrenProps }) => {
  const children = React.Children.map(childrenProps, (child, index) => {
    if (index > 1) {
      logger.dev('Error: The `<SplitView>` accepts a maximum of 2 children.');
    }

    if (ReactIs.isElement(child)) {
      return React.cloneElement(child, { order: index + 1 });
    }

    return child;
  });

  if (children?.filter(child => child?.props?.bringToFront)?.length > 1) {
    logger.dev('Error: The `bringToFront` prop should be assigned to 1 or 0 SplitView items.');
  }

  const frontItem = children?.find(child => child?.props?.bringToFront)?.props.order;

  return (
    <SplitViewContext.Provider
      value={{
        columnArrangement,
        hasOverlap: !!frontItem,
      }}>
      <Grid
        {...(frontItem
          ? getOverlappingGridProperties(frontItem)
          : { columns: '12', gap: getGridProperties(columnArrangement).gap, alignY: 'center' })}>
        {children}
      </Grid>
    </SplitViewContext.Provider>
  );
};

export type ColumnArrangement = '8-4' | '4-8' | '6-6';

interface Props {
  columnArrangement?: ColumnArrangement;
  children: JSX.Element[];
}

interface SplitViewProps {
  Item: typeof SplitViewItem;
}

SplitView.Item = SplitViewItem;

export default SplitView;
